





































import { Component, Prop, Vue } from "vue-property-decorator";
import { FileManager } from "uknow-react-components";
import api from "@/api";
import mainAuth from "@/auth";
import store from "@/store";

@Component({
  components: { FileManager },
})
export default class ExternalShare extends Vue {
  get accessToken() {
    return mainAuth().accessToken;
  }

  get vourusUrl() {
    return store.state.vourusUrl;
  }
}
